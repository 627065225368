import env from '../env'
import { messageCentreEnvSettings } from './messageCentre'

export enum Features {
  messageCentre = 'messageCentre',
}

const featureFlags = (feature: Features) => {
  const currentEnv = env.env || 'production'

  switch (feature) {
    case Features.messageCentre:
      return messageCentreEnvSettings[currentEnv]
    default:
      return false
  }
}

export default featureFlags

export interface EnvSettings {
  e2e: boolean
  master: boolean
  staging: boolean
  production: boolean
}
